import { findChildren } from '@remirror/core';
import React, {
  createContext,
  FC,
  memo,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useEditorState } from '../default';
import {
  NodeCollectionContext,
  NodeCollectionProviderProps,
  NodeItem,
  NODE_STATE_META,
} from './nodeTypes';

const Context = createContext<NodeCollectionContext | undefined>(undefined);

const InnerNodeCollectionProvider: FC<NodeCollectionContext> = memo(
  function InnerNodeCollectionProvider({ nodeItems, manager, children }) {
    return (
      <Context.Provider value={{ nodeItems, manager }}>
        {children}
      </Context.Provider>
    );
  },
);

/**
 * Context Provider for extracting a list of nodes out of
 * a document.
 */
export const NodeCollectionProvider: FC<NodeCollectionProviderProps> = ({
  nodeTypes,
  stateHook = useEditorState,
  children,
}) => {
  const [nodes, setNodes] = useState<NodeItem[]>();
  const [nodesTimestamp, setNodesTimestamp] = useState<number>();
  const {
    localState,
    manager,
    latestTransaction,
    immediateStateDocChangeTimestamp,
  } = stateHook();
  const isSnippet: boolean = latestTransaction?.getMeta(NODE_STATE_META);

  useEffect(() => {
    if (
      localState &&
      !isSnippet &&
      (!nodes ||
        !immediateStateDocChangeTimestamp ||
        !nodesTimestamp ||
        immediateStateDocChangeTimestamp > nodesTimestamp)
    ) {
      const { doc } = localState;
      setNodes(
        findChildren({
          node: doc,
          predicate: ({ type }) =>
            nodeTypes.findIndex((i) => i === type || i === type.name) >= 0,
          descend: true,
          // type: localState.schema.nodes.snippet,
        }).map((np) => ({
          id: np.node.attrs.id,
          pos: np.pos,
          node: np.node,
          size: np.node.nodeSize,
        })),
      );
      setNodesTimestamp(immediateStateDocChangeTimestamp);
    }
  }, [localState]);

  return (
    <InnerNodeCollectionProvider {...{ nodeItems: nodes, manager }}>
      {children}
    </InnerNodeCollectionProvider>
  );
};

/**
 * Custom hook to access state context
 */
export const useNodeCollection = (): NodeCollectionContext => {
  const context = useContext(Context);
  if (!context) {
    throw new Error(
      `No Context available. Are you invoking this from inside a <NodeCollectionProvider>?`,
    );
  }
  return context;
};
