import React from 'react';

import { Icon, IconProps } from '../base-icon';

export const H5Icon = (props: IconProps) => (
  <Icon name='h5 icon' viewBox='0 0 641 599' {...props}>
    <path
      d='M633.71 382.719v38.379h-84.52l-4.835 42.334c3.516-2.051 8.13-3.858 13.843-5.42 5.713-1.563 11.304-2.344 16.772-2.344 21.192 0 37.476 6.274 48.853 18.823 11.377 12.55 17.065 30.151 17.065 52.808 0 13.672-3.051 26.05-9.155 37.134-6.103 11.084-14.673 19.604-25.708 25.561-11.035 5.957-24.072 8.936-39.111 8.936-13.38 0-25.928-2.76-37.647-8.277-11.719-5.517-20.898-13.086-27.539-22.705-6.64-9.619-9.912-20.483-9.814-32.593h49.512c.488 7.813 2.978 14.014 7.47 18.604 4.492 4.59 10.4 6.885 17.725 6.885 16.601 0 24.902-12.305 24.902-36.914 0-22.754-10.156-34.131-30.469-34.131-11.523 0-20.117 3.71-25.78 11.133l-39.259-9.229 13.037-108.984h124.659zM176 0c8.837 0 16 7.163 16 16v32c0 8.837-7.163 16-16 16h-32v128h192V64h-32c-8.837 0-16-7.163-16-16V16c0-8.837 7.163-16 16-16h160c8.837 0 16 7.163 16 16v32c0 8.837-7.163 16-16 16h-32v320h32c8.837 0 16 7.163 16 16v32c0 8.837-7.163 16-16 16H304c-8.837 0-16-7.163-16-16v-32c0-8.837 7.163-16 16-16h32V256H144v128h32c8.837 0 16 7.163 16 16v32c0 8.837-7.163 16-16 16H16c-8.837 0-16-7.163-16-16v-32c0-8.837 7.163-16 16-16h32V64H16C7.163 64 0 56.837 0 48V16C0 7.163 7.163 0 16 0h160z'
      fill='currentColor'
      fillRule='nonzero'
    />
  </Icon>
);
