import { getDocument } from '@remirror/core';
import cheerio from 'cheerio';
import { DOMParser, Schema, Slice } from 'prosemirror-model';

export const extractContent = ({
  content,
  schema,
  doc = getDocument(),
}: {
  content: string;
  schema: Schema;
  doc?: Document;
}): Slice => {
  const $ = cheerio.load(content);

  content = $('body').html() as string;

  const element = doc.createElement('div');
  element.innerHTML = content.trim();

  const slice = DOMParser.fromSchema(schema).parseSlice(element);

  return slice;
};
