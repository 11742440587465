import { ExtensionManager } from '@remirror/core';
import { RemirrorStateListenerParams } from '@remirror/react';
import throttle from 'lodash.throttle';
import { EditorState } from 'prosemirror-state';
import React, {
  createContext,
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { KwilioObjectNode } from '../../core';
import {
  Dispatch,
  EditorStateContext,
  EditorStateProviderProps,
} from './editorStateProviderTypes';

const Context = createContext<EditorStateContext | undefined>(undefined);

/**
 * Context provider for local/remote state
 */
export const EditorStateProvider: FC<EditorStateProviderProps> = memo(
  function EditorStateProvider({
    remoteData,
    updateRemoteData,
    cleanup,
    docId,
    updateRemoteThrottle = 2000,
    localStateThrottle = 1000,
    children,
  }) {
    const [state, setState] = useState<Omit<EditorStateContext, 'setManager'>>(
      {},
    );

    const [manager, setManager] = useState<ExtensionManager>();
    const [lastUpdatedRemoteData, setLastUpdatedRemoteData] = useState<
      KwilioObjectNode
    >();

    /**
     * Memoized throttled function to update remote data
     */
    const updateRemote = useCallback(
      throttle(
        (doc: KwilioObjectNode, state: EditorState) => {
          updateRemoteData?.(doc, state);
        },
        updateRemoteThrottle,
        {
          trailing: true,
        },
      ),
      [updateRemoteData],
    );

    /**
     * Throttled function to update local
     * in case localStateThrottle is a number
     */
    const updateLocal =
      typeof localStateThrottle === 'number'
        ? throttle(
            (state: EditorState) => {
              setState((prev) => ({
                ...prev,
                localState: state,
              }));
            },
            localStateThrottle,
            {
              trailing: true,
            },
          )
        : () => ({});

    /**
     * Memoized function to update local state
     * and trigger throttled updateRemote function
     */
    const onStateChange: Dispatch = useCallback(
      ({
        newState,
        getObjectNode,
        internalUpdate,
        tr,
        createStateFromContent,
      }: RemirrorStateListenerParams) => {
        setState((prev) => ({
          ...prev,
          immediateState: newState,
          immediateStateDocChangeTimestamp: tr?.docChanged
            ? new Date().getTime()
            : prev.immediateStateDocChangeTimestamp,
          latestTransaction: tr,
          createStateFromContent,
        }));
        if (!internalUpdate) {
          updateLocal(newState);
        }
        if (tr?.docChanged) {
          const doc = { ...getObjectNode(), timestamp: new Date().getTime() };
          setLastUpdatedRemoteData(doc);
          updateRemote(doc, newState);
        }
      },
      [updateRemote],
    );

    /**
     * Updates remote state based on remote
     * data changes; and also immediate/local state if they are
     * not defined yet or if remoteData differs from latest data
     * dispatched on updateRemote.
     * In other words: "remote data has been updated from a
     * different source than this provider (i.e. another provider)"
     */
    useEffect(() => {
      let remoteState: EditorState;

      if (remoteData && manager) {
        remoteState = manager.createState({
          content: remoteData, // TODO this is where we could convert html in the future
        });
      }

      const updateLocals =
        !lastUpdatedRemoteData ||
        (remoteData && lastUpdatedRemoteData.timestamp < remoteData.timestamp);

      setState((prev) => ({
        ...prev,
        immediateState:
          !prev.immediateState || updateLocals
            ? remoteState
            : prev.immediateState,
        localState:
          !prev.localState || updateLocals ? remoteState : prev.localState,
        remoteState: remoteState,
      }));

      return cleanup; // cleanup on unmount
    }, [remoteData, manager]);

    /**
     * Resets state if docId
     * has changed
     */
    useEffect(() => {
      if (docId) {
        setState((prev) => ({
          ...prev,
          localState: undefined,
          immediateState: undefined,
          latestTransaction: undefined,
        }));
        setLastUpdatedRemoteData(undefined);
      }
    }, [docId]);

    const dispatch = useCallback(() => {
      setState((prev) => ({
        ...prev,
        localState: prev.immediateState,
      }));
    }, []);

    const _setManager = (_manager: ExtensionManager) => {
      // must clean immediate/local to force recreating these states
      // because of a bug in remirror that does not show the initial
      // state, if an editor is initially rendered with a valid 'value'
      // property
      setState((prev) => ({
        ...prev,
        localState: undefined,
        immediateState: undefined,
      }));
      setManager(_manager);
    };

    return (
      <Context.Provider
        value={{
          ...state,
          docId,
          dispatch,
          manager,
          onStateChange,
          setManager: _setManager,
        }}
      >
        {children}
      </Context.Provider>
    );
  },
);

/**
 * Custom hook to access state context
 */
export const useEditorState = (): EditorStateContext => {
  const context = useContext(Context);
  if (!context) {
    throw new Error(
      `No Context available. Are you invoking this from inside an <EditorStateProvider>?`,
    );
  }
  return context;
};
