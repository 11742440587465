import {
  DOMOutputSpec,
  NodeExtensionSpec,
  ProsemirrorNode,
} from '@remirror/core';
import { HeadingExtension } from '@remirror/core-extensions';
import { Plugin } from 'prosemirror-state';
import { createIdIndexerPlugin, readAttrs } from '../utils';

export class IdCapableHeadingExtension extends HeadingExtension {
  get schema(): NodeExtensionSpec {
    const schema = { ...super.schema };

    // compiler check condition
    if (schema.attrs) {
      schema.attrs.id = {
        default: null,
      };
    }
    schema.parseDOM = this.options.levels.map((level) => ({
      tag: `h${level}`,
      getAttrs: readAttrs({
        attrs: ['id'],
        default: {
          level,
        },
      }),
    }));

    schema.toDOM = (node: ProsemirrorNode) => {
      // compiler check condition
      if (super.schema.toDOM) {
        const toDOM: DOMOutputSpec = [
          super.schema.toDOM(node)[0],
          { id: node.attrs.id },
          0,
        ];
        return toDOM;
      }
      return '';
    };

    return schema;
  }

  readonly plugin = (): Plugin => createIdIndexerPlugin(this.name);
}
