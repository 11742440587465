/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { moveContent, singleSelectedNode } from '@kwilio/editor-utils';
import { EditorView, uniqueId } from '@remirror/core';
import { Plugin } from 'prosemirror-state';

export interface PluginOptions {
  uploadImage?: (file: File) => Promise<string>;
}

const getImages = (data: DataTransfer | null) => {
  if (!data) {
    return [];
  }
  return Array.from(data.files).filter((file) => /image/i.test(file.type));
};

const addImage = async (
  view: EditorView,
  file: File,
  pos: number,
  uploadImage: PluginOptions['uploadImage'],
) => {
  if (!uploadImage) {
    // eslint-disable-next-line no-console
    console.warn(
      `No 'uploadImage' option specified for editor/image-extension.`,
    );
    return;
  }
  const name = `${uniqueId()}.${file.name.split('.').pop()}`;
  const idFile = new File([file], name, {
    type: file.type,
    lastModified: file.lastModified,
  });
  const src = await uploadImage(idFile);
  const { schema } = view.state;
  const node = schema.nodes.image.create({
    src,
  });
  const transaction = view.state.tr.insert(pos, node);
  view.dispatch(transaction);
};

const addImages = async (
  view: EditorView,
  files: File[],
  pos: number,
  uploadImage: PluginOptions['uploadImage'],
  event: Event,
) => {
  event.preventDefault();
  return Promise.all(
    files.map((file) => addImage(view, file, pos, uploadImage)),
  );
};

export const createImagePlugin = ({ uploadImage }: PluginOptions) => {
  return new Plugin({
    props: {
      handleDOMEvents: {
        drop(view, event) {
          const images = getImages(event.dataTransfer);
          const coordinates = view.posAtCoords({
            left: event.clientX,
            top: event.clientY,
          });

          if (images.length === 0) {
            const imageNode = singleSelectedNode(
              view.state.schema.nodes.image,
              view.state,
            );
            if (imageNode && coordinates) {
              event.preventDefault();
              const tr = moveContent({
                start: view.state.selection.from,
                end: view.state.selection.to,
                pos: coordinates.pos,
                state: view.state,
              });
              view.dispatch(tr);
              return true;
            }
            return false;
          }
          if (!coordinates) {
            return false;
          }
          addImages(view, images, coordinates.pos, uploadImage, event);
          return true;
        },

        paste(view, event) {
          const images = getImages(event.clipboardData);
          if (images.length === 0) {
            return false;
          }
          const { tr } = view.state;
          if (!tr.selection.empty) {
            // replacing with image
            tr.deleteSelection();
          }
          const pos = tr.selection.from;
          addImages(view, images, pos, uploadImage, event);
          return true;
        },
      },
    },
  });
};
