import React, { FC, useCallback, useEffect, useState } from 'react';
import { KwilioObjectNode } from '../../core';
import { EditorStateProvider } from '../default';
import { FirebaseEditorStateProviderProps } from './firebaseEditorStateProviderTypes';

export { useEditorState as useFire } from '../default';

export const FirebaseEditorStateProvider: FC<FirebaseEditorStateProviderProps> = ({
  fireStateRef,
  onFirebaseUpdate,
  updateRemoteThrottle,
  cleanup,
  localStateThrottle,
  children,
}) => {
  const [remoteData, setRemoteData] = useState<KwilioObjectNode>();

  const updateRemoteData = useCallback(
    async (node: KwilioObjectNode) => {
      await fireStateRef?.set(node);
      onFirebaseUpdate?.(new Date());
    },
    [fireStateRef, onFirebaseUpdate],
  );

  useEffect(() => {
    const listener = fireStateRef?.onSnapshot((snapshot) => {
      setRemoteData(snapshot.data());
    });
    return () => {
      listener?.();
    }; // clear up on unmount
  }, [fireStateRef]);

  return (
    <EditorStateProvider
      {...{
        docId: fireStateRef?.parent?.parent?.id,
        remoteData,
        updateRemoteData,
        updateRemoteThrottle,
        cleanup,
        localStateThrottle,
      }}
    >
      {children}
    </EditorStateProvider>
  );
};
