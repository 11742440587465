import { ExtensionParams, SchemaParams } from '@remirror/core';
import { Slice } from 'prosemirror-model';
import { PageExtension } from './PageExtension';

export interface CreatePagePluginParams
  extends ExtensionParams<PageExtension>,
    SchemaParams {}

export const breakTransactionTypeMeta = 'break-type';

export const insertAtMeta = 'insert-at';

export enum BreakTransactionTypes {
  delete = 'DELETE',
  insert = 'INSERT',
}

export interface BreakAt {
  pageIndex: number;
  from: number;
  to: number;
  contentOverlapsPage: boolean;
}

export interface InsertAt {
  slice: Slice;
  position: number;
  moveCursorTo?: number;
}

export interface PagePluginState {
  breakAt?: BreakAt;
  insertAt?: InsertAt;
}
