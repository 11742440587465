import { ExtensionManager } from '@remirror/core';
import { Node, NodeType } from 'prosemirror-model';
import { CustomHook } from '../../core';

export const NODE_STATE_META = 'isNodeState';

export interface NodeItem {
  /**
   * DOM id attribute
   */
  id: string;
  /**
   * Position within the document
   */
  pos: number;
  /**
   * Node data that is originated in main document
   */
  node: Node;
  /**
   * The node size. This is important
   * because the snippet list is not
   * recalculated when the content
   * inside the snippet changes.
   */
  size: number;
}

export interface NodeCollectionContext {
  nodeItems?: NodeItem[];
  manager?: ExtensionManager;
}

export interface NodeCollectionProviderProps extends CustomHook {
  /**
   * Array of node types to look for and provide a collection of
   */
  nodeTypes: Array<NodeType | string>;
}

export interface NodeStateProviderProps {
  /**
   * The nodeItem to provide state for.
   */
  nodeItem: NodeItem;
}
