export const browserSelectFile = (accept: string) => (): Promise<File> => {
  const input = document.createElement('input');
  input.type = 'file';
  input.accept = accept;
  input.style.visibility = 'hidden';

  return new Promise<File>((resolve, reject) => {
    input.addEventListener(
      'change',
      () => {
        if (input.files) {
          const file = input.files[0];
          return resolve(file);
        } else {
          return reject();
        }
      },
      false,
    );
    input.click();
  }).finally(() => {
    input.remove();
  });
};
