import React from 'react';

import { Icon, IconProps } from '../base-icon';

export const H6Icon = (props: IconProps) => (
  <Icon name='h6 icon' viewBox='0 0 646 588' {...props}>
    <path
      d='M612.324 368.643v38.818h-2.197c-18.457 0-33.618 4.419-45.484 13.257-11.865 8.838-19.165 21.118-21.9 36.84 11.134-10.937 25.196-16.406 42.188-16.406 18.457 0 33.155 6.739 44.092 20.215 10.938 13.477 16.406 31.152 16.406 53.028 0 13.574-3.198 26.025-9.594 37.353-6.397 11.328-15.332 20.19-26.807 26.587-11.475 6.396-24.194 9.595-38.16 9.595-15.136 0-28.661-3.443-40.575-10.327-11.914-6.885-21.192-16.724-27.832-29.517-6.641-12.793-10.059-27.539-10.254-44.238v-19.776c0-21.973 4.712-41.723 14.136-59.253 9.423-17.529 22.875-31.274 40.356-41.235 17.48-9.961 36.865-14.941 58.154-14.941h7.471zm-44.531 110.302c-6.543 0-12.012 1.514-16.407 4.541-4.394 3.028-7.666 6.739-9.814 11.133v14.795c0 26.953 9.326 40.43 27.979 40.43 7.52 0 13.793-3.37 18.823-10.108 5.03-6.738 7.544-15.185 7.544-25.341 0-10.45-2.564-18.97-7.69-25.562-5.128-6.592-11.94-9.888-20.435-9.888zM176 0c8.837 0 16 7.163 16 16v32c0 8.837-7.163 16-16 16h-32v128h192V64h-32c-8.837 0-16-7.163-16-16V16c0-8.837 7.163-16 16-16h160c8.837 0 16 7.163 16 16v32c0 8.837-7.163 16-16 16h-32v320h32c8.837 0 16 7.163 16 16v32c0 8.837-7.163 16-16 16H304c-8.837 0-16-7.163-16-16v-32c0-8.837 7.163-16 16-16h32V256H144v128h32c8.837 0 16 7.163 16 16v32c0 8.837-7.163 16-16 16H16c-8.837 0-16-7.163-16-16v-32c0-8.837 7.163-16 16-16h32V64H16C7.163 64 0 56.837 0 48V16C0 7.163 7.163 0 16 0h160z'
      fill='currentColor'
      fillRule='nonzero'
    />
  </Icon>
);
