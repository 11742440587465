import {
  Attrs,
  CommandNodeTypeParams,
  convertCommand,
  EditorState,
  ExtensionCommandReturn,
  ExtensionManagerNodeTypeParams,
  isNodeActive,
  KeyBindings,
  NodeExtension,
  NodeExtensionSpec,
  NodeGroup,
  ProsemirrorCommandFunction,
} from '@remirror/core';
import { wrapIn } from 'prosemirror-commands';
import { NodeRange, NodeType } from 'prosemirror-model';
import { Plugin } from 'prosemirror-state';
import { createIdIndexerPlugin, readAttrs } from '../utils';

const snippetRange = (
  state: EditorState,
): [NodeRange | null | undefined, number] => {
  const { $from } = state.selection;
  const parentDepth = $from.depth - 1;
  if (parentDepth < 0) {
    return [null, parentDepth];
  }
  /**
   * Resolved position of first sibling start
   */
  const $start = state.doc.resolve($from.start(parentDepth) + 1);
  /**
   * Resolved position of last sibling end
   */
  const $end = state.doc.resolve($from.end(parentDepth) - 1);

  const range = $start.blockRange($end);
  return [range, parentDepth];
};

const toggleSnippet = (
  type: NodeType,
  attrs?: Attrs,
): ProsemirrorCommandFunction => (state, dispatch) => {
  const isActive = isNodeActive({ state, type });
  if (isActive) {
    const [range, parentDepth] = snippetRange(state);
    if (range) {
      if (dispatch) {
        dispatch(state.tr.lift(range, parentDepth - 1).scrollIntoView());
      }
      return true;
    }
    return false;
  } else {
    return wrapIn(type, attrs)(state, dispatch);
  }
};

export class SnippetExtension extends NodeExtension {
  readonly name = 'snippet';

  readonly schema: NodeExtensionSpec = {
    attrs: {
      ...this.extraAttrs(),
      id: {
        default: null,
      },
    },
    content: 'paragraph*',
    group: NodeGroup.Block,
    defining: true,
    draggable: false,
    isolating: true,
    parseDOM: [{ tag: 'snippet', getAttrs: readAttrs({ attrs: ['id'] }) }],
    toDOM: (node) => ['snippet', { id: node.attrs.id }, 0],
  };

  public commands({ type }: CommandNodeTypeParams): ExtensionCommandReturn {
    return {
      toggleSnippet: (): ProsemirrorCommandFunction => toggleSnippet(type),
    };
  }

  public keys({ type }: ExtensionManagerNodeTypeParams): KeyBindings {
    return {
      'Ctrl-[': convertCommand(toggleSnippet(type)),
      Enter: ({ state, dispatch }): boolean => {
        if (isNodeActive({ state, type })) {
          const {
            selection: { empty, $from, $to, to },
          } = state;
          if (empty && !$from.nodeBefore) {
            // empty selection with no node before it
            const [range, parentDepth] = snippetRange(state);
            if (range?.$to.pos === to) {
              // selection ends at snippet end
              const toLift = $from.blockRange($to);
              if (dispatch && toLift) {
                dispatch(
                  state.tr.lift(toLift, parentDepth - 1).scrollIntoView(),
                );
                return true;
              }
            }
          }
        }
        return false;
      },
    };
  }

  readonly plugin = (): Plugin => createIdIndexerPlugin(this.name);
}
