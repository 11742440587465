import { BubbleContent, IconButton } from '@remirror/editor-wysiwyg';
import {
  AlignCenterIcon,
  AlignLeftIcon,
  AlignRightIcon,
} from '@remirror/ui-icons';
import clsx from 'clsx';
import { NodeSelection } from 'prosemirror-state';
import React, { FC, useEffect, useState } from 'react';
import { ComponentProps } from '../view/ComponentView';
import './ImageComponent.scss';
import { Align, alignImageCommand } from './ImageExtension';

const ImageMenu: FC<{ align: (align: Align) => void }> = ({ align }) => {
  return (
    <div className="KwilioImageMenu">
      <BubbleContent>
        <IconButton onClick={() => align('left')} state="default" index={0}>
          <AlignLeftIcon
            variant="inverse"
            color="white"
            backgroundColor="black"
          />
        </IconButton>
        <IconButton onClick={() => align('middle')} state="default" index={1}>
          <AlignCenterIcon
            variant="inverse"
            color="white"
            backgroundColor="black"
          />
        </IconButton>
        <IconButton onClick={() => align('right')} state="default" index={2}>
          <AlignRightIcon
            variant="inverse"
            color="white"
            backgroundColor="black"
          />
        </IconButton>
      </BubbleContent>
    </div>
  );
};

interface ResizerProps {
  top?: true;
  onDrag: (width: number) => void;
  onResize: (width: number) => void;
}

const Resizer: FC<ResizerProps> = ({ onDrag, onResize, top }) => {
  const handleMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    const { parentElement } = event.currentTarget;
    if (parentElement) {
      const { pageX: startX } = event;
      const { width: startWidth } = parentElement.getBoundingClientRect();
      let newWidth = startWidth;

      const handleMouseMove = (e: MouseEvent) => {
        const { clientX: currentX } = e;
        const diff = currentX - startX;
        if (top) {
          newWidth = startWidth - diff;
        } else {
          newWidth = startWidth + diff;
        }
        newWidth = newWidth > 0 ? newWidth : 1;
        onDrag(newWidth);
      };

      const handleMouseUp = (e: Event) => {
        e.preventDefault();
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
        onResize(newWidth);
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }
  };

  return (
    <span
      className={clsx('KwilioImageResizer', top ? 'top-left' : 'bottom-right')}
      onMouseDown={handleMouseDown}
    />
  );
};

export const ImageComponent: FC<ComponentProps> = ({
  node,
  isSelected,
  view,
  getPos,
  isEditable,
}) => {
  const [width, setWidth] = useState<number>();

  useEffect(() => {
    if (!width && node.attrs.width) {
      setWidth(node.attrs.width);
    }
  }, [width && node.attrs.width]);

  const select = () => {
    if (typeof getPos === 'function' && isEditable) {
      const $pos = view.state.doc.resolve(getPos());
      const transaction = view.state.tr.setSelection(new NodeSelection($pos));
      view.dispatch(transaction);
    }
  };

  const align = (align: Align) => {
    alignImageCommand(align)(view.state, view.dispatch);
  };

  const handleSelect = (event: React.MouseEvent) => {
    event.preventDefault();
    select();
  };

  const handleDragStart = (event: React.DragEvent) => {
    if (!isSelected || !isEditable) {
      event.preventDefault();
      return false;
    }
  };

  const handleResize = (width: number) => {
    if (typeof getPos === 'function' && isEditable) {
      const pos = getPos();
      let transaction = view.state.tr.setNodeMarkup(pos, undefined, {
        ...node.attrs,
        width,
      });
      transaction = transaction.setSelection(
        new NodeSelection(transaction.doc.resolve(pos)),
      );
      view.dispatch(transaction);
    }
  };

  return (
    <div
      className={clsx([
        'KwilioImageWrapper',
        { 'ProseMirror-selectednode': isSelected },
        `align-${node?.attrs.align}`,
      ])}
    >
      <div className="KwilioImage">
        <img
          src={node?.attrs.src}
          onClick={handleSelect}
          onDragStart={handleDragStart}
          style={{ width }}
        />
        {isSelected && (
          <>
            <ImageMenu align={align} />
            <Resizer top onDrag={setWidth} onResize={handleResize} />
            <Resizer onDrag={setWidth} onResize={handleResize} />
          </>
        )}
      </div>
    </div>
  );
};
