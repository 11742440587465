import { uniqueId } from '@remirror/core';
import { Plugin, PluginSpec } from 'prosemirror-state';
import { ReplaceAroundStep, ReplaceStep } from 'prosemirror-transform';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type StringMap = { [key: string]: any };

export interface ReadAttrsParams {
  attrs: string[];
  default?: StringMap;
}

export const readAttrs = (params: ReadAttrsParams) => {
  return (node: Node | string): StringMap => {
    const map: StringMap = params.default || {};
    params.attrs.forEach((attr) => {
      map[attr] = (node as Element).getAttribute(attr);
    });
    return map;
  };
};

export const createIdIndexerPlugin = (typeName: string): Plugin =>
  new Plugin({
    appendTransaction: (transactions, _, newState) => {
      for (const transaction of transactions) {
        for (const step of transaction.steps) {
          if (
            step instanceof ReplaceStep ||
            step instanceof ReplaceAroundStep
          ) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const { from } = step as any;
            const pos = newState.doc.resolve(from);
            const { nodeAfter: node } = pos;
            if (node?.type.name === typeName && !node.attrs.id) {
              return newState.tr.setNodeMarkup(pos.pos, undefined, {
                ...node.attrs,
                id: uniqueId(),
              });
            }
          }
        }
      }
      return undefined;
    },
  } as PluginSpec);
