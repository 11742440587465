/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { singleSelectedNode } from '@kwilio/editor-utils';
import {
  Attrs,
  bool,
  Cast,
  CommandNodeTypeParams,
  EditorState,
  EditorView,
  NodeExtension,
  NodeExtensionOptions,
  NodeExtensionSpec,
  NodeView,
  NodeViewMethod,
  ProsemirrorCommandFunction,
  ProsemirrorNode,
} from '@remirror/core';
import { ResolvedPos } from 'prosemirror-model';
import { NodeSelection } from 'prosemirror-state';
import { readAttrs } from '../utils';
import { ComponentView } from '../view';
import { createImagePlugin, PluginOptions } from './createImagePlugin';
import { ImageComponent } from './ImageComponent';
// import { getAttrs } from './image-utils';

const hasCursor = <T extends object>(
  arg: T,
): arg is T & { $cursor: ResolvedPos } => {
  return bool(Cast(arg).$cursor);
};

type ImageExtensionOptions = PluginOptions & NodeExtensionOptions;

export type Align = 'middle' | 'left' | 'right';

export const alignImageCommand = (align: Align) => (
  state: EditorState,
  dispatch?: EditorView['dispatch'],
): boolean => {
  const imageType = state.schema.nodes.image;
  const selectedImage = singleSelectedNode(imageType, state);
  if (!selectedImage) {
    return false;
  }
  const attrs = {
    ...selectedImage.attrs,
    align,
  };
  const { selection } = state;
  if (dispatch) {
    let tr = state.tr.setNodeMarkup(selection.$from.pos, undefined, attrs);
    tr = tr.setSelection(new NodeSelection(tr.doc.resolve(selection.from)));
    dispatch(tr);
  }
  return true;
};

export class ImageExtension extends NodeExtension<ImageExtensionOptions> {
  get name() {
    return 'image' as const;
  }

  get schema(): NodeExtensionSpec {
    return {
      inline: true,
      attrs: {
        ...this.extraAttrs(null),
        align: { default: 'middle' },
        alt: { default: '' },
        // crop: { default: null },
        height: { default: null },
        width: { default: null },
        // rotate: { default: null },
        src: { default: null },
        // title: { default: '' },
      },
      group: 'inline',
      draggable: true,
      parseDOM: [
        {
          tag: 'img[src]',
          getAttrs: readAttrs({
            attrs: ['src', 'alt', 'align'],
            default: { align: 'middle' },
          }),
        },
      ],
      toDOM: (node: ProsemirrorNode) => {
        return ['img', node.attrs];
      },
    };
  }

  public commands({ type }: CommandNodeTypeParams) {
    return {
      insertImage: (attrs?: Attrs): ProsemirrorCommandFunction => (
        state,
        dispatch,
      ) => {
        const { selection } = state;
        const position = hasCursor(selection)
          ? selection.$cursor.pos
          : selection.$to.pos;
        const node = type.create(attrs);
        const transaction = state.tr.insert(position, node);

        if (dispatch) {
          dispatch(transaction);
        }

        return true;
      },
      alignImageLeft: (): ProsemirrorCommandFunction =>
        alignImageCommand('left'),
      alignImageMiddle: (): ProsemirrorCommandFunction =>
        alignImageCommand('middle'),
      alignImageRight: (): ProsemirrorCommandFunction =>
        alignImageCommand('right'),
    };
  }

  public plugin() {
    return createImagePlugin({ uploadImage: this.options.uploadImage });
  }

  public nodeView(): NodeViewMethod {
    return (node, view, getPos): NodeView =>
      new ComponentView(view, node, getPos, ImageComponent);
  }
}
