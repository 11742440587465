import { EditorState } from 'prosemirror-state';
import React, { FC, memo, useMemo } from 'react';
import { KwilioObjectNode } from '../../core';
import { EditorStateProvider } from '../default';
import { useNodeCollection } from './NodeCollectionProvider';
import { NodeStateProviderProps, NODE_STATE_META } from './nodeTypes';

/**
 * Wrapper EditorStateProvider for a Node
 */
export const NodeStateProvider: FC<NodeStateProviderProps> = memo(
  function NodeStateProvider({ nodeItem, children }) {
    const { manager } = useNodeCollection();

    const remoteData = useMemo(
      () =>
        ({
          type: 'doc',
          content: [nodeItem?.node.toJSON()],
          timestamp: new Date().getTime(),
        } as KwilioObjectNode),
      [nodeItem],
    );

    const updateRemoteData = (
      nodeJson: KwilioObjectNode,
      nodeState: EditorState,
    ) => {
      // compiler check
      if (nodeItem && manager) {
        const state = manager.getState();
        const content = nodeState.doc.firstChild?.content;
        // another compiler check
        if (content) {
          const node = state.schema.nodeFromJSON(nodeJson.content?.[0]);
          const tr = state.tr
            .replaceWith(nodeItem.pos, nodeItem.pos + nodeItem.size, node)
            .setMeta(NODE_STATE_META, true);
          manager.view.dispatch(tr);
          nodeItem.size = node.nodeSize;
        }
      }
    };

    return (
      <EditorStateProvider
        {...{
          // docId: id,
          remoteData,
          updateRemoteData,
          updateRemoteThrottle: 0,
        }}
      >
        {children}
      </EditorStateProvider>
    );
  },
);
