import { fromHTML } from '@remirror/core';
import React, { FC } from 'react';
import { useEditorState } from '../providers';
import { ControlledEditor } from './ControlledEditor';
import { KwilioEditorProps } from './editorTypes';

export const KwilioEditor: FC<KwilioEditorProps> = ({
  type = 'immediate',
  stateHook = useEditorState,
  editable,
  ...rest
}) => {
  const context = stateHook();
  const { onStateChange, manager } = context;
  const map = {
    immediate: context.immediateState,
    local: context.localState,
    remote: context.remoteState,
  };
  const state =
    map[type] ||
    manager?.createState({
      content: '<p></p>',
      stringHandler: fromHTML,
    });

  const cEditable = editable !== undefined ? editable : type === 'immediate';

  return (
    <ControlledEditor
      value={state}
      onStateChange={onStateChange}
      editable={cEditable}
      setManager={context.setManager}
      {...rest}
    />
  );
};
