import { changeHeadingLevel, moveContent } from '@kwilio/editor-utils';
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { CustomHook } from '../../core';
import { useEditorState } from '../default';
import { NodeCollectionProvider, useNodeCollection } from '../node';
import {
  HeadingTreeContext,
  HeadingTreeNode,
  MoveFunction,
  TreeNodeMap,
} from './headingTreeTypes';

const Context = createContext<HeadingTreeContext | undefined>(undefined);

const getParentByLevel = (
  previous: HeadingTreeNode,
  level: number,
): HeadingTreeNode => {
  let parent: HeadingTreeNode | undefined = previous;
  while (parent && parent.level >= level) {
    parent = parent.parent;
  }
  return parent || previous;
};

const getDescendants = (
  treeNodes: HeadingTreeNode[],
  parent: HeadingTreeNode,
): HeadingTreeNode[] => {
  const index = treeNodes.indexOf(parent);
  const descendants: HeadingTreeNode[] = [];
  for (let i = index + 1; i < treeNodes.length && parent; i++) {
    const descendant = treeNodes[i];
    if (descendant.level > parent.level) {
      descendants.push(descendant);
    } else {
      break;
    }
  }
  return descendants;
};

const InnerHeadingTreeProvider: FC = ({ children }) => {
  const { nodeItems, manager } = useNodeCollection();
  const { root, treeNodeMap, treeNodes } = useMemo((): {
    treeNodes?: HeadingTreeNode[];
  } & Pick<Partial<HeadingTreeContext>, 'root' | 'treeNodeMap'> => {
    if (nodeItems && manager) {
      const to = manager.getState().doc.nodeSize - 2;
      const root: HeadingTreeNode = {
        level: 0,
        from: 0,
        to,
        children: [],
      };
      let previous: HeadingTreeNode = root;
      const treeNodes: HeadingTreeNode[] = [];
      const treeNodeMap: TreeNodeMap = {};
      for (const nodeItem of nodeItems) {
        const level: number = nodeItem.node.attrs.level;
        const from: number = nodeItem.pos;
        const treeNode: HeadingTreeNode = {
          level,
          from,
          to,
          nodeItem,
          children: [],
          parent: getParentByLevel(previous, level),
        };
        treeNode.parent?.children.push(treeNode);
        treeNodes.push(treeNode);
        treeNodeMap[nodeItem.id] = treeNode;
        previous = treeNode;
      }
      for (const treeNode of treeNodes) {
        const { parent } = treeNode;
        if (parent) {
          const cIndex = parent.children.indexOf(treeNode);
          if (parent.children.length > cIndex + 1) {
            // if there is not a next sibling tree node
            treeNode.to = parent.children[cIndex + 1].from;
          } else {
            // no tree sibling
            treeNode.to = parent.to;
          }
        }
      }
      return { root, treeNodeMap, treeNodes };
    }
    return {};
  }, [nodeItems]);

  const moveNode: MoveFunction = useCallback(
    (node: HeadingTreeNode, parent: HeadingTreeNode, index: number) => {
      let didMove = false;
      if (manager) {
        const state = manager.getState();
        const { from, to } = node;
        let where: number;
        const offset: number = parent.level - node.level + 1;
        if (parent.children.length === 0) {
          // the parent has no children yet
          where = parent.to;
        } else if (index < parent.children.length) {
          // there is another node there already
          const existing = parent.children[index];
          const cIndex = parent.children.indexOf(node);
          if (cIndex == -1 || index < cIndex) {
            // moving to a different parent, or same parent but to a lower position
            where = existing.from;
          } else {
            // moving to same parent, but to a higher position
            where = existing.to;
          }
        } else {
          // it is trying to push a new child (end of array)
          where = parent.children[index - 1].to;
        }
        let { tr } = state;
        const descendants = getDescendants(
          treeNodes || [], // compiler check
          node,
        );
        if (offset !== 0) {
          // moving to a different parent level
          tr = changeHeadingLevel({
            pos: node.from,
            offset,
            tr,
          });
          for (const descendant of descendants) {
            tr = changeHeadingLevel({
              pos: descendant.from,
              offset,
              tr,
            });
          }
          didMove = true;
        }
        if (where !== from && where !== to) {
          // moving to a different doc position
          tr = moveContent({
            start: from,
            end: to,
            pos: where,
            state,
            tr,
          });
          didMove = true;
        }
        if (didMove) {
          manager.view.dispatch(tr);
        }
      }
      return didMove;
    },
    [manager, treeNodes],
  );

  return (
    <Context.Provider
      value={{
        nodeItems,
        manager,
        root,
        treeNodeMap,
        treeNodes,
        moveNode,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const HeadingTreeProvider: FC<CustomHook> = ({
  stateHook = useEditorState,
  children,
}) => {
  return (
    <NodeCollectionProvider nodeTypes={['heading']} stateHook={stateHook}>
      <InnerHeadingTreeProvider>{children}</InnerHeadingTreeProvider>
    </NodeCollectionProvider>
  );
};

/**
 * Custom hook to access state context
 */
export const useHeadingTree = (): HeadingTreeContext => {
  const context = useContext(Context);
  if (!context) {
    throw new Error(
      `No Context available. Are you invoking this from inside a <HeadingTreeProvider>?`,
    );
  }
  return context;
};
