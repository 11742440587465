import React, { FC, useEffect, useState } from 'react';
import { NodeItem, useNodeCollection } from '../providers';
import { NodeInputProps } from './editorTypes';

const updatePositions = (
  nodeItems: NodeItem[],
  start: number,
  offset: number,
) => {
  for (let i = start; i < nodeItems.length; i++) {
    nodeItems[i].pos += offset;
  }
};

/**
 * Input wrapper that takes `textContent` from
 * a `nodeItem`'s `node` and dispatches transaction
 * replacing the `node` content with user input.
 * Useful for `inline` nodes, like headings.
 */
export const NodeInput: FC<NodeInputProps> = ({
  nodeItem,
  onChange,
  tag: Tag = 'input',
  ...rest
}) => {
  const [value, setValue] = useState<string>('');
  const { nodeItems, manager } = useNodeCollection();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (manager && nodeItems) {
      const newValue = e.target.value;
      const tr = manager
        ?.getState()
        .tr.insertText(
          e.target.value,
          nodeItem.pos + 1,
          nodeItem.pos + nodeItem.size - 1,
        );
      // .setMeta(NODE_STATE_META, true);
      manager?.view.dispatch(tr);
      nodeItem.size = newValue.length + 2;
      updatePositions(
        nodeItems,
        nodeItems.indexOf(nodeItem) + 1,
        newValue.length - value.length,
      );
      setValue(newValue);
      onChange?.(e);
    }
  };

  useEffect(() => {
    setValue(nodeItem.node.textContent);
  }, [nodeItem]);

  return <Tag {...rest} value={value} onChange={handleChange} />;
};
