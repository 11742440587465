import {
  Attrs,
  DispatchFunction,
  Extension,
  ExtensionCommandReturn,
  ProsemirrorCommandFunction,
} from '@remirror/core';
import { EditorState, TextSelection } from 'prosemirror-state';

export type GoToExtensionAttrs = Attrs<{
  /**
   * Position to set the cursor to.
   */
  position: number;
}>;

export class GoToExtension extends Extension {
  readonly name = 'goto';

  public commands(): ExtensionCommandReturn {
    return {
      goTo: ({ position }: GoToExtensionAttrs): ProsemirrorCommandFunction => (
        state: EditorState,
        dispatch: DispatchFunction | undefined,
      ): boolean => {
        const tr = state.tr.setSelection(
          new TextSelection(state.doc.resolve(position)),
        );
        dispatch?.(tr);
        return true;
      },
    };
  }
}
