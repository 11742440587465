/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/core';
import { EditorWrapper } from '@remirror/editor-wysiwyg';
import { useRemirrorContext, useRemirrorManager } from '@remirror/react';
import { useEffect } from 'react';
import { InnerEditorProps } from './editorTypes';
import { BubbleMenu, MenuBar } from './MenuBar';

export const ManagedEditor: React.FC<InnerEditorProps> = ({
  setManager,
  ...rest
}: InnerEditorProps) => {
  const manager = useRemirrorManager();

  useEffect(() => {
    if (manager.initialized) {
      setManager?.(manager);
    }
  }, [manager.initialized]);

  // useEffect(() => {
  //   if (htmlToParse && manager.initialized) {
  //     const slice = extractContent({
  //       content: htmlToParse,
  //       schema: manager.data.schema,
  //     });
  //     const state = manager.getState();
  //     // console.log(slice);

  //     let tr = state.tr.insert(1, slice.content);
  //     tr = tr.setSelection(TextSelection.create(tr.doc, 0, 0));
  //     manager.view.dispatch(tr);
  //   }
  // }, [htmlToParse, manager.initialized]);
  return <Inner {...rest} />;
};

const Inner: React.FC<InnerEditorProps> = ({
  activateLink,
  linkActivated,
  deactivateLink,
  menuBar = true,
  bubbleMenu = true,
  onMenuAction,
  selectImage,
  uploadImage,
}) => {
  const { getRootProps } = useRemirrorContext();

  return (
    <EditorWrapper
      css={css`
        .ProseMirror.remirror-editor {
          min-height: initial;
        }
      `}
    >
      {menuBar && (
        <MenuBar
          activateLink={activateLink}
          onMenuAction={onMenuAction}
          selectImage={selectImage}
          uploadImage={uploadImage}
        />
      )}

      {bubbleMenu && (
        <BubbleMenu
          linkActivated={linkActivated}
          deactivateLink={deactivateLink}
          activateLink={activateLink}
          onMenuAction={onMenuAction}
          selectImage={selectImage}
          uploadImage={uploadImage}
        />
      )}
      <div {...getRootProps()} data-testid="kwilio-editor" />
    </EditorWrapper>
  );
};
