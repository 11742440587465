import {
  DOMOutputSpec,
  ExtensionManagerNodeTypeParams,
  NodeExtension,
  NodeExtensionOptions,
  NodeExtensionSpec,
  NodeGroup,
} from '@remirror/core';
import { css } from '@remirror/react-utils';
import { Plugin } from 'prosemirror-state';
import { createPagePlugin } from './createPagePlugin';

interface PageOptions extends NodeExtensionOptions {
  content?: string;
}

export class PageExtension extends NodeExtension<PageOptions> {
  readonly name = 'page';

  get defaultOptions(): PageOptions {
    return {
      content: 'inline*',
    };
  }

  // getter for future this.options access
  get schema(): NodeExtensionSpec {
    return {
      attrs: this.extraAttrs(),
      group: NodeGroup.Block,
      parseDOM: [{ tag: 'page' }],
      toDOM: (): DOMOutputSpec => ['page', 0],
      content: this.options.content,
      isolating: true,
      defining: true,
      marks: '_',
      code: false,
      //defining: true
    };
  }

  readonly styles = (): string => {
    return css`
      page {
        margin: 0 1em 1em 1em;
        padding: 2.5cm 3cm 2.5cm 3cm;
        box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
        width: 210mm;
        height: 97mm;
        display: block;
      }

      @media print {
        page {
          page-break-after: always;
          box-shadow: none;
        }
      }
    `;
  };

  readonly plugin = (params: ExtensionManagerNodeTypeParams): Plugin => {
    return createPagePlugin({ extension: this, ...params });
  };
}
