import { Transaction } from 'prosemirror-state';
import {
  createOrUseTransaction,
  CreateOrUseTransactionParams,
} from './transaction-utils';

export const moveContent = ({
  start,
  end,
  pos,
  state,
  tr,
}: {
  /**
   * The starting position of the content to move
   */
  start: number;
  /**
   * The ending position of the content to move
   */
  end: number;
  /**
   * Where to move the content to
   */
  pos: number;
} & CreateOrUseTransactionParams): Transaction => {
  tr = createOrUseTransaction({ state, tr });
  const slice = tr.doc.slice(start, end);
  tr = tr.replace(pos, undefined, slice);
  const { mapping } = tr;
  start = mapping.map(start);
  end = mapping.map(end);
  tr = tr.delete(start, end);
  return tr;
};
