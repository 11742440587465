/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/core';
import {
  GoToExtension,
  IdCapableHeadingExtension,
  ImageExtension,
  SnippetExtension,
} from '@kwilio/editor-extensions';
import { browserSelectFile } from '@kwilio/editor-utils';
import { deepMerge } from '@remirror/core';
import {
  BlockquoteExtension,
  BoldExtension,
  BulletListExtension,
  HardBreakExtension,
  HorizontalRuleExtension,
  ItalicExtension,
  LinkExtension,
  ListItemExtension,
  OrderedListExtension,
  ParagraphExtension,
  StrikeExtension,
  UnderlineExtension,
} from '@remirror/core-extensions';
import { wysiwygEditorTheme } from '@remirror/editor-wysiwyg';
import { DropCursorExtension } from '@remirror/extension-drop-cursor';
import {
  ManagedRemirrorProvider,
  RemirrorExtension,
  RemirrorManager,
} from '@remirror/react';
import { RemirrorThemeProvider } from '@remirror/ui';
import { memo, useState } from 'react';
import { ControlledEditorProps } from './editorTypes';
import { ManagedEditor } from './ManagedEditor';

export const ControlledEditor = memo(function ControlledEditor({
  value,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onStateChange = (): void => {},
  setManager,
  htmlToParse,
  menuBar,
  bubbleMenu,
  onMenuAction,
  snippetExtension = true,
  theme: themeProp,
  uploadImage,
  selectImage = browserSelectFile('image/*'),
  ...props
}: ControlledEditorProps) {
  const [linkActivated, setLinkActivated] = useState(false);

  const activateLink = () => {
    setLinkActivated(true);
  };

  const deactivateLink = () => {
    setLinkActivated(false);
  };

  const theme = themeProp
    ? deepMerge(wysiwygEditorTheme, themeProp)
    : wysiwygEditorTheme;

  return (
    <RemirrorThemeProvider theme={theme}>
      <RemirrorManager>
        <RemirrorExtension
          Constructor={SnippetExtension}
          exclude={{ styles: !snippetExtension, keys: !snippetExtension }}
        />
        <RemirrorExtension Constructor={ParagraphExtension} />
        <RemirrorExtension Constructor={DropCursorExtension} priority={1} />
        <RemirrorExtension Constructor={BoldExtension} />
        <RemirrorExtension Constructor={UnderlineExtension} />
        <RemirrorExtension Constructor={ItalicExtension} />
        <RemirrorExtension Constructor={BlockquoteExtension} />
        <RemirrorExtension
          Constructor={LinkExtension}
          activationHandler={activateLink}
          priority={1}
        />
        <RemirrorExtension Constructor={StrikeExtension} />
        <RemirrorExtension Constructor={IdCapableHeadingExtension} />
        <RemirrorExtension Constructor={HorizontalRuleExtension} />
        <RemirrorExtension Constructor={ListItemExtension} />
        <RemirrorExtension Constructor={BulletListExtension} />
        <RemirrorExtension Constructor={OrderedListExtension} />
        <RemirrorExtension Constructor={HardBreakExtension} />
        <RemirrorExtension Constructor={GoToExtension} />
        <RemirrorExtension
          Constructor={ImageExtension}
          uploadImage={uploadImage}
        />

        <ManagedRemirrorProvider
          value={value}
          onStateChange={onStateChange}
          // stringHandler={fromHTML}
          {...props}
        >
          <ManagedEditor
            htmlToParse={htmlToParse}
            setManager={setManager}
            {...{
              activateLink,
              deactivateLink,
              linkActivated,
              menuBar,
              bubbleMenu,
              onMenuAction,
              selectImage,
              uploadImage,
            }}
          />
        </ManagedRemirrorProvider>
      </RemirrorManager>
    </RemirrorThemeProvider>
  );
});
