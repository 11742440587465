/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  EditorView,
  getPluginState,
  ProsemirrorNode,
  Transaction,
} from '@remirror/core';
import { Node } from 'prosemirror-model';
import {
  EditorState,
  Plugin,
  PluginSpec,
  TextSelection,
} from 'prosemirror-state';
import { ReplaceStep } from 'prosemirror-transform';
import {
  BreakAt,
  breakTransactionTypeMeta,
  BreakTransactionTypes,
  CreatePagePluginParams,
  InsertAt,
  insertAtMeta,
  PagePluginState,
} from './pageTypes';

const getPageOffset = (doc: ProsemirrorNode, pageIndex: number) => {
  let pageOffset = 0;
  doc.forEach((_, offset, index) => {
    if (index === pageIndex) {
      pageOffset = offset + 2; // position where page content starts
    }
  });
  return pageOffset;
};

export const createPagePlugin = ({
  extension, // schema,
}: CreatePagePluginParams): Plugin => {
  const { pluginKey } = extension;

  const isReplace = (tr: Transaction) =>
    tr.steps.some((step) => step instanceof ReplaceStep);

  const isBreakable = (
    tr: Transaction,
    oldState: EditorState,
  ): BreakAt | undefined => {
    const { doc: oldDoc, selection: oldSelection } = oldState;
    const oldPageIndex = oldSelection.$from.index(0);
    const oldPage = oldDoc.child(oldPageIndex);

    const { doc, selection } = tr;
    const pageIndex = selection.$from.index(0);
    const page = doc.child(pageIndex);

    const oldPageContentSize =
      (oldPage.content as any).content.length > 0
        ? (oldPage.content as any).content[0].textContent.length
        : 0; // TODO find a better way to get node internal contents size sum
    const pageContentSize = (page.content as any).content.reduce(
      (acc: number, content: Node) => acc + content.textContent.length,
      0,
    );

    // console.log(oldSelection.from, oldPageContentSize);
    // console.log(tr.selection.from, pageContentSize);

    const length = pageContentSize - oldPageContentSize;

    if (length > 0) {
      // increased

      const pageOffset = getPageOffset(doc, pageIndex);

      const charLimit = 99;
      const diff = pageContentSize - charLimit;

      if (diff > 0) {
        // TODO calculate length for page break
        return {
          pageIndex,
          from: pageOffset + charLimit,
          to: pageOffset + pageContentSize,
          contentOverlapsPage:
            tr.selection.from + length > pageOffset + charLimit,
        };
      }
    } else {
      // decreased
    }
    return undefined;
  };

  return new Plugin<PagePluginState>({
    key: pluginKey,

    view: () => ({
      update: (view: EditorView): void => {
        const { dispatch, state } = view;

        const pluginState = getPluginState<PagePluginState>(pluginKey, state);
        const { breakAt, insertAt } = pluginState;

        if (breakAt) {
          const { doc } = state;
          const { from, to, pageIndex, contentOverlapsPage } = breakAt;
          const slice = doc.slice(from, to);

          let tr = state.tr
            .setMeta(breakTransactionTypeMeta, BreakTransactionTypes.delete)
            .delete(from, to);

          // const hasNextPage = tr.doc.childCount > pageIndex + 1;
          let position;
          let moveCursorTo;
          let cursorOffset = 0;
          if (doc.childCount > pageIndex + 1) {
            // is there a next page already?
            position = tr.mapping.map(getPageOffset(doc, pageIndex + 1)); // yes - get the start of the page content
          } else {
            //	2 = 1 (end of paragraph) + 1 (end of page)
            position = tr.mapping.map(to) + 2; // no - use resulting to resulting position after deletion + node offset
            cursorOffset = 2;
          }
          if (contentOverlapsPage) {
            moveCursorTo = position + slice.content.size + cursorOffset;
          }
          const insertAt: InsertAt = {
            slice,
            position,
            moveCursorTo,
          };
          tr = tr.setMeta(insertAtMeta, insertAt);

          dispatch(tr);
        }

        if (insertAt) {
          const {
            // slice, position,
            moveCursorTo,
          } = insertAt;
          let tr = state.tr
            .insert(insertAt.position, insertAt.slice.content)
            .setMeta(breakTransactionTypeMeta, BreakTransactionTypes.insert);
          if (moveCursorTo) {
            tr = tr.setSelection(TextSelection.create(tr.doc, moveCursorTo));
          }
          dispatch(tr);
        }
      },
    }),

    state: {
      init: (): PagePluginState => {
        return {};
      },
      apply: (
        tr: Transaction,
        pState: PagePluginState,
        oldState: EditorState,
        //newState
      ): PagePluginState => {
        if (tr.docChanged) {
          const breakType = tr.getMeta(breakTransactionTypeMeta);

          if (breakType === BreakTransactionTypes.delete) {
            return {
              insertAt: tr.getMeta(insertAtMeta),
            };
          } else {
            if (isReplace(tr)) {
              const breakAt = isBreakable(tr, oldState);
              if (breakAt) {
                return {
                  breakAt,
                };
              } else if (breakType === BreakTransactionTypes.insert) {
                return {}; // reset state to stop recursion
              }
            }
          }
        }
        return pState;
      },
    },
  } as PluginSpec<PagePluginState>);
};
