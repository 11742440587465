import { EditorState, NodeType, ProsemirrorNode } from '@remirror/core';

export const singleSelectedNode = (
  type: NodeType,
  state: EditorState,
): false | ProsemirrorNode => {
  const { selection, doc } = state;
  if (selection.empty) {
    return false;
  }
  if (selection.to !== selection.from + 1) {
    return false;
  }
  const { nodeAfter } = doc.resolve(selection.from);
  if (nodeAfter?.type !== type) {
    return false;
  }
  return nodeAfter;
};
