import { EditorState, Transaction } from 'prosemirror-state';

export interface CreateOrUseTransactionParams {
  /**
   * A transaction to pipe
   */
  tr?: Transaction;
  /**
   * A state to start a new transaction from
   */
  state?: EditorState;
}

export const createOrUseTransaction = ({
  tr,
  state,
}: CreateOrUseTransactionParams): Transaction => {
  if (!tr) {
    if (state) {
      ({ tr } = state);
    } else {
      throw new Error('Neither a transaction or state has been provided.');
    }
  }
  return tr;
};
