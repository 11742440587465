import { Transaction } from 'prosemirror-state';
import {
  createOrUseTransaction,
  CreateOrUseTransactionParams,
} from './transaction-utils';

export const changeHeadingLevel = ({
  pos,
  offset,
  ...params
}: {
  /**
   * Starting position of the heading node
   */
  pos: number;
  /**
   * Number value to add / subtract to the level
   */
  offset: number;
} & CreateOrUseTransactionParams): Transaction => {
  let tr = createOrUseTransaction(params);
  const { nodeAfter: node } = tr.doc.resolve(pos);
  if (node?.type.name === 'heading') {
    const { level }: { level?: number } = node.attrs;
    if (level) {
      const newLevel = level + offset;
      if (newLevel < 1 || newLevel > 6) {
        throw new Error(`Resulting level "${newLevel}" is not valid.`);
      }
      tr = tr.setNodeMarkup(pos, undefined, {
        ...node.attrs,
        level: newLevel,
      });
      return tr;
    }
  }
  throw new Error('Position is not of a valid heading node.');
};
