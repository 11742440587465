import { NodeView, ProsemirrorNode } from '@remirror/core';
import { EditorView } from 'prosemirror-view';
import React, { PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';

export type ComponentProps = PropsWithChildren<{
  view: EditorView;
  node: ProsemirrorNode;
  isSelected: boolean;
  isEditable: boolean;
  getPos: boolean | (() => number);
}>;

type Component = (props: ComponentProps) => React.ReactElement | null;

export class ComponentView implements NodeView {
  isSelected = false;
  dom: HTMLElement | null;

  private readonly view: EditorView;
  private node: ProsemirrorNode;
  private readonly getPos: ComponentProps['getPos'];
  private readonly component: Component;

  constructor(
    view: EditorView,
    node: ProsemirrorNode,
    getPos: ComponentProps['getPos'],
    component: Component,
  ) {
    this.view = view;
    this.node = node;
    this.getPos = getPos;
    this.component = component;
    this.dom = node.type.spec.inline
      ? document.createElement('span')
      : document.createElement('div');
    this.renderElement();
  }

  renderElement(): void {
    ReactDOM.render(
      <this.component
        {...{
          view: this.view,
          node: this.node,
          isSelected: this.isSelected,
          isEditable: this.view?.editable,
          getPos: this.getPos,
        }}
      />,
      this.dom,
    );
  }

  update(node: ProsemirrorNode): boolean {
    if (node.type !== this.node.type) {
      return false;
    }
    this.node = node;
    this.renderElement();
    return true;
  }

  selectNode(): void {
    if (this.view.editable) {
      this.isSelected = true;
      this.renderElement();
    }
  }

  deselectNode(): void {
    if (this.view.editable) {
      this.isSelected = false;
      this.renderElement();
    }
  }

  stopEvent(): true {
    return true;
  }

  destroy(): void {
    if (this.dom) {
      ReactDOM.unmountComponentAtNode(this.dom);
    }
    this.dom = null;
  }

  ignoreMutation(): true {
    return true;
  }
}
